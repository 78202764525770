import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { NewsState } from './state';

const defaultState: NewsState = {
  newsList: {
    count: 0,
    next: 0,
    previous: 0,
    total: 0,
    total_count: 0,
    results: [],
  },
  newsArticle: null,
};

export const newsModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};