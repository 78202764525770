import { api } from '@/api';
import { ISetLanguageRequest, IUserRegister } from '@/interfaces';
import router from '@/router';
import { getLocalSessionId, getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { dispatchGetCategoriesSubscriptionsIDList, dispatchGetProductsInWishlist } from '../products/actions';
import { commitSetInvoiceDetail, commitSetProductsInvoice, commitSetProductsInWishlist } from '../products/mutations';
import { State } from '../state';
import {
    commitSetFooterMenuItemList,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetMainMenuItemList,
    commitSetPageDetail,
    commitSetToken,
    commitSetUserProfile,
} from './mutations';
import { AppNotification, MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogIn(
        context: MainContext, payload: { username: string; password: string, anon_session_id: string | null },
    ) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password, payload.anon_session_id);
            const token = response.data.token;
            saveLocalToken(token);
            commitSetToken(context, token);
            commitSetLoggedIn(context, true);
            commitSetLogInError(context, false);
            commitSetUserProfile(context, response.data.user);
            await dispatchRouteLoggedIn(context);
            dispatchGetProductsInWishlist(context, {anon_session_id: getLocalSessionId()});
			dispatchGetCategoriesSubscriptionsIDList(context, {});
        } catch (err) {
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionRegisterUser(context: MainContext, payload: any) {
        try {
            const response = await api.submitRegisterUser(payload);
            
            const token = response.data.token;
            saveLocalToken(token);
            commitSetToken(context, token);
            commitSetLoggedIn(context, true);
            commitSetUserProfile(context, response.data.user);
            dispatchGetProductsInWishlist(context, {anon_session_id: getLocalSessionId()});
            dispatchGetCategoriesSubscriptionsIDList(context, {});
            // await dispatchRouteLoggedIn(context);
            return true;
        } catch (err) {
            return err.response ? err.response.data : {};
        }
    },
    async actionFinishRegister(context: MainContext) {
        try {
            const response = await api.finishRegister(context.state.token);
            return true;
        } catch (err) {
            return false;
        }
    },
    async actionAuthUserByGoogle(context: MainContext, payload: any) {
        try {
            const response = await api.submitAuthorizeGoogle(payload);
            
            const token = response.data.token;
            saveLocalToken(token);
            commitSetToken(context, token);
            commitSetLoggedIn(context, true);
            commitSetUserProfile(context, response.data.user);
            dispatchGetProductsInWishlist(context, {anon_session_id: getLocalSessionId()});
            dispatchGetCategoriesSubscriptionsIDList(context, {});
            return {
                success: true,
                result: response.data.user_created,
            };
        } catch (err) {
            await dispatchRemoveLogIn(context);
            return {
              success: false,
              result: false,
            };
        }
    },
    async actionAuthUserByFacebook(context: MainContext, payload: any) {
        try {
            const response = await api.submitAuthorizeFacebook(payload);
            
            const token = response.data.token;
            saveLocalToken(token);
            commitSetToken(context, token);
            commitSetLoggedIn(context, true);
            commitSetUserProfile(context, response.data.user);
            dispatchGetProductsInWishlist(context, {anon_session_id: getLocalSessionId()});
            dispatchGetCategoriesSubscriptionsIDList(context, {});
            return {
                success: true,
                result: response.data.user_created,
            };
        } catch (err) {
            await dispatchRemoveLogIn(context);
            return {
              success: false,
              result: false,
            };
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        // await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitSetProductsInvoice(context, null);
        commitSetInvoiceDetail(context, null);
        commitSetProductsInWishlist(context, []);
        // commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/sign-up') {
            router.push('/my-cabinet');
        }
    },
    async submitPasswordRecovery(context: MainContext, payload: { email: string }) {
        try {
            const response = await api.passwordRecovery(payload.email);
            return true;
        } catch (error) {
            return false;
        }
    },
    async submitResetPassword(context: MainContext, payload: { password: string, token: string }) {
        try {
            const response = await api.resetPassword(payload.password, payload.token);
            return response.status;
        } catch (error) {
            return error.response.status;
        }
    },
    async actionGetCountriesChoices(context: MainContext) {
        try {
            const response = await api.getCountriesChoices();
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return null;
        }
    },
    async actionGetTimezonesChoices(context: MainContext) {
        try {
            const response = await api.getTimezonesChoices();
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return null;
        }
    },

    async actionSetLanguageRequest(context: MainContext, payload: ISetLanguageRequest) {
        try {
            const response = await api.setLanguageRequest(context.rootState.main.token, payload);
            return true;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return false;
        }
    },

    // async actionSetInventory(context: MainContext, payload: IProducts) {
    //     try {
    //         const response = await api.setInventory(context.rootState.main.token, payload);
    //         return true;
    //     } catch (err) {
    //         await dispatchCheckApiError(context, err);
    //         return false;
    //     }
    // },
    // async actionSetOrderInfoWithQuantity(context: MainContext, payload: { getParams: string }) {
    //     try {
    //         const response = await api.setOrderInfoWithQuantity(context.rootState.main.token, payload.getParams);
    //         return true;
    //     } catch (err) {
    //         await dispatchCheckApiError(context, err);
    //         return false;
    //     }
    // },
    // async actionGetDiscounts(context: MainContext) {
    //     try {
    //         const response = await api.getDiscounts();
    //         return response.data;
    //     } catch (err) {
    //         await dispatchCheckApiError(context, err);
    //         return null;
    //     }
    // },
    async actionSearchByPhrase(context: MainContext, payload: {search: string, full_page?: boolean}) {
        try {
            const response = await api.searchByPhrase(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return {total_results: 0, results: []};
        }
    },
    async actionGetNovaPoshtaCities(context: MainContext, payload: {search: string}) {
        try {
            const response = await api.getNovaPoshtaCities(payload.search);
            return response.data;
        } catch (err) {
            return [];
        }
    },
    async actionGetNovaPoshtaWarehouses(context: MainContext, payload: {cityRef: string}) {
        try {
            const response = await api.getNovaPoshtaWarehouses(payload.cityRef);
            return response.data;
        } catch (err) {
            return [];
        }
    },
    async actionUserEdit(context: MainContext, payload: FormData) {
        try {
            const response = await api.editUser(context.rootState.main.token, payload);
            commitSetUserProfile(context, response.data);
            return true;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return false;
        }
    },
    async actionChangeUser1cAdress(context: MainContext, payload: {uid: string}) {
        try {
            const response = await api.changeUser1cAdress(context.rootState.main.token, payload);
            commitSetUserProfile(context, response.data);
            return true;
        } catch (err) {
            return false;
        }
    },
    async actionGetPageDetail(context: MainContext, payload: { slug: string }) {
        try {
            const response = await api.getPageDetail(context.rootState.main.token, payload);
            commitSetPageDetail(context, response.data);
        } catch (err) {
            commitSetPageDetail(context, null);
            await dispatchCheckApiError(context, err);
        }
    },
    async actionGetPetsKindsChoices(context: MainContext) {
        try {
            const response = await api.getPetsKinds(context.rootState.main.token);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetBreedsChoices(context: MainContext, payload: {kind: number}) {
        try {
            const response = await api.getPetsBreeds(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionGetMyPetsList(context: MainContext) {
        try {
            const response = await api.getMyPetsList(context.rootState.main.token);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionSaveMyPet(context: MainContext, payload: FormData) {
        try {
            const response = await api.saveMyPet(context.rootState.main.token, payload);
            return true;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return false;
        }
    },
    async actionGetMyDeliveryAddressesList(context: MainContext, payload: {editable?: boolean}) {
        try {
            const response = await api.getMyDeliveryAddressesList(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            return [];
        }
    },
    async actionSaveMyDeliveryAddress(context: MainContext, payload: FormData) {
        try {
            const response = await api.saveMyDeliveryAddress(context.rootState.main.token, payload);
            return response.data;
        } catch (err) {
            await dispatchCheckApiError(context, err);
            return null;
        }
    },
    async actionGetFooterMenuItemList(context: MainContext) {
        try {
            const response = await api.getFooterMenuItemList(context.rootState.main.token, {});
            commitSetFooterMenuItemList(context, response.data);
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            commitSetFooterMenuItemList(context, {
                catalog: [],
                info: [],
                service: [],
            });
        }
    },
    async actionGetMainMenuItemList(context: MainContext) {
        try {
            const response = await api.getMainMenuItemList(context.rootState.main.token, {});
            commitSetMainMenuItemList(context, response.data);
        } catch (err) {
            // await dispatchCheckApiError(context, err);
            commitSetMainMenuItemList(context, []);
        }
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchRegisterUser = dispatch(actions.actionRegisterUser);
export const dispatchFinishRegister = dispatch(actions.actionFinishRegister);
export const dispatchSubmitPasswordRecovery = dispatch(actions.submitPasswordRecovery);
export const dispatchSubmitResetPassword = dispatch(actions.submitResetPassword);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchSearchByPhrase = dispatch(actions.actionSearchByPhrase);
export const dispatchUserEdit = dispatch(actions.actionUserEdit);
export const dispatchChangeUser1cAdress = dispatch(actions.actionChangeUser1cAdress);
export const dispatchGetPageDetail = dispatch(actions.actionGetPageDetail);
export const dispatchGetMyPetsList = dispatch(actions.actionGetMyPetsList);
export const dispatchGetPetsKindsChoices = dispatch(actions.actionGetPetsKindsChoices);
export const dispatchGetBreedsChoices = dispatch(actions.actionGetBreedsChoices);
export const dispatchSaveMyPet = dispatch(actions.actionSaveMyPet);
export const dispatchGetFooterMenuItemList = dispatch(actions.actionGetFooterMenuItemList);
export const dispatchGetMainMenuItemList = dispatch(actions.actionGetMainMenuItemList);
export const dispatchGetNovaPoshtaCities = dispatch(actions.actionGetNovaPoshtaCities);
export const dispatchGetNovaPoshtaWarehouses = dispatch(actions.actionGetNovaPoshtaWarehouses);
export const dispatchGetMyDeliveryAddressesList = dispatch(actions.actionGetMyDeliveryAddressesList);
export const dispatchSaveMyDeliveryAddress = dispatch(actions.actionSaveMyDeliveryAddress);
export const dispatchSetLanguageRequest = dispatch(actions.actionSetLanguageRequest);
export const dispatchAuthUserByGoogle = dispatch(actions.actionAuthUserByGoogle);
export const dispatchAuthUserByFacebook = dispatch(actions.actionAuthUserByFacebook);

// export const dispatchSetInventory = dispatch(actions.actionSetInventory);
// export const dispatchSetOrderInfoWithQuantity = dispatch(actions.actionSetOrderInfoWithQuantity);
// export const dispatchGetDiscounts = dispatch(actions.actionGetDiscounts);
