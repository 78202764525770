import axios from 'axios';
import { apiUrl } from '@/env';
import { IPet, IPage, ISearchResult, IUserProfile, IUserRegister, IFooterMenu, INovaPoshtaCity, IUserDeliveryAddress, INovaPoshtaWarehouse, IMenuItem, IFeedbackForm } from './interfaces';
import { INewsArticle, INewsListResponse } from './interfaces/news';
import { ICreatureKind, IDeliveryOption, IInvoiceDetail, IInvoiceList, IInvoicesListResponse, IPaymentMethod, IProductBrand, IProductDetail, IProductProperty, IProductsCategoriesLightResponse, IProductsCategoriesResponse, IProductsCategory, IProductsCategoryLight, IProductsListResponse, UserProductViewHistory } from './interfaces/products';
import { IMainBanner } from './interfaces/banners';
import { ITestimonialsResponse } from './interfaces/testimonials';
import { i18n } from './main';
import { IPersonalDiscountsResponse } from './interfaces/special_offers';

function authHeaders(token: string) {
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {};
  }
}

function authUploadHeaders(token: string) {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
}

// function authBasicHeaders(username: string = 'web', password: string = 'Zxcvb!0912') {
//   const authInfo = btoa(`${username}:${password}`);
//   return {
//     headers: {
//       Authorization: `Basic ${authInfo}`,
//     },
//   };
// }

function updateData(data) {
  const additionalData = {
    front_language: i18n.locale,
  };
  return {...data, ...additionalData};
}

export const api = {
  async logInGetToken(username: string, password: string, anon_session_id?: string | null) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    if (anon_session_id) {
      params.append('anon_session_id', anon_session_id);
    }

    return axios.post<{token: string, user: IUserProfile}>(`${apiUrl}/api/v1/accounts/login/`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/accounts/me/`, authHeaders(token));
  },
  // async getUsers(token: string) {
  //   return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  // },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password_reset/`, { email });
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/password_reset/confirm/`, {
      password,
      token,
    });
  },
  async submitRegisterUser(data: any) {
    return axios.post<{token: string, user: IUserProfile}>(`${apiUrl}/api/v1/accounts/register/client/`, data);
  },
  async finishRegister(token: string) {
    return axios.post(`${apiUrl}/api/v1/accounts/register/finish/`, {}, authHeaders(token));
  },
  async submitAuthorizeGoogle(data: any) {
    return axios.post<{token: string, user: IUserProfile, user_created: boolean}>(`${apiUrl}/api/v1/google/authorize/`, data);
  },
  async submitAuthorizeFacebook(data: any) {
    return axios.post<{token: string, user: IUserProfile, user_created: boolean}>(`${apiUrl}/api/v1/facebook/authorize/`, data);
  },
  async getCountriesChoices() {
    return axios.get(`${apiUrl}/api/v1/accounts/get_countries/`);
  },
  async getTimezonesChoices() {
    return axios.get(`${apiUrl}/api/v1/accounts/get_timezones/`);
  },

  async setLanguageRequest(token: string, data: any) {
    return axios.post<IUserRegister>(`${apiUrl}/api/v1/accounts/set_language/`, data, authHeaders(token));
  },

  async getTestimonialsList(token: string, data: {product_id?: number | null, user_id?: number | null}, rowQuery: string) {
    return axios.post<ITestimonialsResponse>(`${apiUrl}/api/v1/testimonials/list/${rowQuery}`, data, authHeaders(token));
  },
  async submitTestimonial(token: string, data: {product: number, text: string, mark: number}) {
    return axios.post(`${apiUrl}/api/v1/testimonials/submit_testimonial/`, data, authHeaders(token));
  },
  async getNewsList(token: string, rowQuery: string) {
    return axios.post<INewsListResponse>(`${apiUrl}/api/v1/news/list/${rowQuery}`, updateData({}), authHeaders(token));
  },
  async getNewsArticle(token: string, data: { article_id: number }) {
    // const unionLink = await getUnionBackendLink();
    return axios.post<INewsArticle>(`${apiUrl}/api/v1/news/view/`, updateData(data), authHeaders(token));
  },
  async getDeliveryOptionsList(token: string) {
    return axios.post<IDeliveryOption[]>(`${apiUrl}/api/v1/products/delivery_options/list/`, updateData({}), authHeaders(token));
  },
  async getCategoriesList(token: string, data: {category?: string | null}) {
    return axios.post<IProductsCategoriesResponse>(`${apiUrl}/api/v1/products/categories/list/`, updateData(data), authHeaders(token));
  },
  async getBrandsList(token: string, data: any) {
    return axios.post<IProductBrand[]>(`${apiUrl}/api/v1/products/brands/list/`, updateData(data), authHeaders(token));
  },
  async getBrandDetail(token: string, data: {codename}) {
    return axios.post<IProductBrand>(`${apiUrl}/api/v1/products/brands/view/`, updateData(data), authHeaders(token));
  },
  async getPopularCategoriesList(token: string) {
    return axios.post<IProductsCategoryLight[]>(`${apiUrl}/api/v1/products/categories/popular/list/`, updateData({}), authHeaders(token));
  },
  async getProductsFilters(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/products/filters/`, updateData(data), authHeaders(token));
  },
  async getProductsInWishlist(token: string, data: {anon_session_id?: string | null}) {
    return axios.post(`${apiUrl}/api/v1/products/wishlist/products_list/`, updateData(data), authHeaders(token));
  },
  async toggleProductInWishlist(token: string, data: {
    anon_session_id?: string | null,
    product_id: number,
    action: string,
  }) {
    return axios.post(`${apiUrl}/api/v1/products/wishlist/toggle_product/`, data, authHeaders(token));
  },
  async getCategoriesSubscriptionsList(token: string, data: any, rowQuery: string) {
    return axios.post<IProductsCategoriesLightResponse>(
      `${apiUrl}/api/v1/products/subscriptions/list/${rowQuery}`, updateData(data), authHeaders(token),
    );
  },
  async getCategoriesSubscriptionsIDList(token: string, data: any) {
    return axios.post(`${apiUrl}/api/v1/products/subscriptions/id_list/`, updateData(data), authHeaders(token));
  },
  async toggleCategorySubscription(token: string, data: {
    category_id: number, action: string,
  }) {
    return axios.post(`${apiUrl}/api/v1/products/subscriptions/toggle_category/`, data, authHeaders(token));
  },
  async getCompareProductsList(token: string, data: { compare_products_list: number[] }) {
    return axios.post<IProductProperty[]>(
      `${apiUrl}/api/v1/products/compare/list/`, updateData(data), authHeaders(token),
    );
  },
  async getProductsWishlist(token: string, data: { anon_session_id?: string | null }, rowQuery: string) {
    return axios.post<IProductsListResponse>(
      `${apiUrl}/api/v1/products/wishlist/list/${rowQuery}`, updateData(data), authHeaders(token),
    );
  },
  async getProductsWishlistCount(token: string, data: { anon_session_id?: string | null }) {
    return axios.post<{count: number}>(
      `${apiUrl}/api/v1/products/wishlist/count/`, data, authHeaders(token),
    );
  },
  async getProductsList(token: string, data: {category?: string, filters?: any}, rowQuery: string) {
    return axios.post<IProductsListResponse>(`${apiUrl}/api/v1/products/list/${rowQuery}`, updateData(data), authHeaders(token));
  },
  async getProductsByTagList(token: string, data: {category?: string, tag?: string}, rowQuery: string) {
    return axios.post<IProductsListResponse>(`${apiUrl}/api/v1/products/list/${rowQuery}`, updateData(data), authHeaders(token));
  },
  async getProductsByKindList(token: string, data: {creature_kind?: number, brand?: string | null}, rowQuery: string) {
    return axios.post<IProductsListResponse>(`${apiUrl}/api/v1/products/list/${rowQuery}`, updateData(data), authHeaders(token));
  },
  async searchProductsList(token: string, data: {search: string, order_by?: string}, rowQuery: string) {
    return axios.post<IProductsListResponse>(`${apiUrl}/api/v1/products/list/search/${rowQuery}`, updateData(data), authHeaders(token));
  },
  async getFooterMenuItemList(token: string, data) {
    return axios.post<IFooterMenu>(`${apiUrl}/api/v1/menu/footer/list/`, updateData(data), authHeaders(token));
  },
  async getMainMenuItemList(token: string, data) {
    return axios.post<IMenuItem[]>(`${apiUrl}/api/v1/menu/main/list/`, updateData(data), authHeaders(token));
  },
  async getProductDetail(token: string, data: {product_codename: string, anon_session_id?: string | null}) {
    return axios.post<IProductDetail>(`${apiUrl}/api/v1/products/view/`, updateData(data), authHeaders(token));
  },
  async getProductsViewHistoryList(token: string, data: any) {
    return axios.post<UserProductViewHistory[]>(`${apiUrl}/api/v1/products/view_history/list/`, updateData(data), authHeaders(token));
  },
  async getPopularKindsProductsList(token: string, data: { brand?: string | null}) {
    return axios.post<ICreatureKind[]>(`${apiUrl}/api/v1/products/popular_kinds_products/`, updateData(data), authHeaders(token));
  },
  async getRecentInvoiceDetails(token: string, data: {anon_session_id?: string | null}) {
    return axios.post<IInvoiceDetail>(`${apiUrl}/api/v1/payments/invoices/get_recent_invoice/`, updateData(data), authHeaders(token));
  },
  async getInvoiceDetails(token: string, data: {invoice_id: number, promocode?: string, anon_session_id?: string | null}) {
    return axios.post<IInvoiceDetail>(`${apiUrl}/api/v1/payments/invoices/get_invoice_details/`, updateData(data), authHeaders(token));
  },
  async saveInvoiceDetails(token: string, data: any) {
    return axios.post<IInvoiceDetail>(`${apiUrl}/api/v1/payments/invoices/set_invoice_details/`, updateData(data), authHeaders(token));
  },
  async processInvoice(token: string, data: {invoice_id: number, payment_method?: string}) {
    return axios.post<{
      checkout_url: string,
      invoice: IInvoiceDetail,
    }>(`${apiUrl}/api/v1/payments/invoices/process_invoice/`, updateData(data), authHeaders(token));
  },
  async getOrdersList(token: string, data: any, rowQuery: string) {
    return axios.post<IInvoicesListResponse>(`${apiUrl}/api/v1/payments/invoices/list/${rowQuery}`, updateData(data), authHeaders(token));
  },
  async getPaymentMethodsList(token: string) {
    return axios.post<IPaymentMethod[]>(`${apiUrl}/api/v1/payments/payment_methods/list/`, updateData({}), authHeaders(token));
  },
  async requestProductPurchase(
    token: string, data: {
      product_property: number, quantity: number, invoice?: number | null,
      anon_session_id?: string | null, one_click?: boolean, phone?: string,
    },
  ) {
    return axios.post<{id: number}>(
      `${apiUrl}/api/v1/products/purchase/request/`, updateData(data), authHeaders(token),
    );
  },
  async requestBuyOneClick(
    token: string, data: {
      invoice: number, phone?: string,
    },
  ) {
    return axios.post<{id: number}>(
      `${apiUrl}/api/v1/products/purchase/buy_one_click/`, updateData(data), authHeaders(token),
    );
  },
  async toggleProductInCart(
    token: string, data: {invoice_item: number, action: string, quantity?: number},
  ) {
    return axios.post<IInvoiceDetail>(
      `${apiUrl}/api/v1/products/purchase/toggle_product_in_cart/`, updateData(data), authHeaders(token),
    );
  },
  async removeFromCart(token: string, data: {id: number, anon_session_id?: string | null}) {
    return axios.post(`${apiUrl}/api/v1/products/purchase/remove_from_cart/`, data, authHeaders(token));
  },
  async getMainBanners(token: string, data: any) {
    return axios.post<IMainBanner[]>(`${apiUrl}/api/v1/banners/main/list/`, updateData(data), authHeaders(token));
  },
  async getNovaPoshtaCities(search: string) {
    return axios.get<INovaPoshtaCity[]>(`${apiUrl}/api/v1/accounts/novaposhta/get_cities/?search=${search}`);
  },
  async getNovaPoshtaWarehouses(cityRef: string) {
    return axios.get<INovaPoshtaWarehouse[]>(`${apiUrl}/api/v1/accounts/novaposhta/get_warehouses/?cityRef=${cityRef}`);
  },
  async searchByPhrase(token: string, data: {search: string, full_page?: boolean}) {
    return axios.post<{total_results: number, results: ISearchResult[]}>(`${apiUrl}/api/v1/accounts/search_by_phrase/`, updateData(data), authHeaders(token));
  },
  async editUser(token: string, data: FormData) {
    return axios.post<IUserProfile>(`${apiUrl}/api/v1/accounts/me/`, data, authHeaders(token));
  },
  async changeUser1cAdress(token: string, data: {uid: string}) {
    return axios.post<IUserProfile>(`${apiUrl}/api/v1/accounts/change_1c_adress/`, data, authHeaders(token));
  },
  async getPageDetail(token: string, data: { slug: string }) {
    return axios.post<IPage>(`${apiUrl}/api/v1/pages/get_page/`, updateData(data), authHeaders(token));
  },
  async getMyPetsList(token: string) {
    return axios.post<IPet[]>(`${apiUrl}/api/v1/accounts/pets/list/`, updateData({}), authHeaders(token));
  },
  async getPetsKinds(token: string) {
    return axios.post(`${apiUrl}/api/v1/accounts/get_creature_kinds/`, updateData({}), authHeaders(token));
  },
  async getPetsBreeds(token: string, data: {kind: number}) {
    return axios.post(`${apiUrl}/api/v1/accounts/get_breeds/`, updateData(data), authHeaders(token));
  },
  async saveMyPet(token: string, data: FormData) {
    return axios.post(`${apiUrl}/api/v1/accounts/pets/save/`, data, authUploadHeaders(token));
  },
  async getMyDeliveryAddressesList(token: string, data: {editable?: boolean}) {
    return axios.post<IUserDeliveryAddress[]>(`${apiUrl}/api/v1/accounts/delivery_address/list/`, data, authHeaders(token));
  },
  async saveMyDeliveryAddress(token: string, data: FormData) {
    return axios.post<{id: number}>(`${apiUrl}/api/v1/accounts/delivery_address/save/`, data, authUploadHeaders(token));
  },
  async getMyPersonalDiscounts(token: string) {
    return axios.post<IPersonalDiscountsResponse>(`${apiUrl}/api/v1/special_offers/personal_discounts/`, {}, authHeaders(token));
  },
  async submitSendFeedback(token: string, data: IFeedbackForm) {
    return axios.post(`${apiUrl}/api/v1/feedback/send_feedback/`, data, authHeaders(token));
  },

};
